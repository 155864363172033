export const spacing = {
  xxs: '4px',
  xs: '8px',
  xss: '12px',
  s: '16px',
  m: '24px',
  l: '48px',
  xl: '96px',
  xxl: '192px',
  side: '32px',
  menuHeight: '56px',
  mega: '17.5rem', // 280px
  none: '0',
} as const;

export type Spacing = keyof typeof spacing;
